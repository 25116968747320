.btn {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    background: #000;
    color: #fff;
}

.btn:hover:not(:disabled) {
    opacity: 0.9;
}

.btn:disabled {
    opacity: 0.6;
    cursor: not-allowed;
}

.btn .loader-container {
    position: absolute;
    top: 0;
    right: -20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    scale: 0.24;
    bottom: 0;
}

/* Optional variants */
.btn.secondary {
    background: #f5f5f5;
    color: #000;
}

.btn.outline {
    background: transparent;
    border: 1px solid #e5e5e5;
    color: #000;
}

.btn.outline:hover:not(:disabled) {
    border-color: #000;
}
